import * as React from "react"
import { Helmet } from "react-helmet"
import 'normalize.css'
import '../components/layout.scss'
import '../components/animations.scss'
import Layout from "../components/layout"
import Nav from "../components/nav"
import Section from "../components/section"
import Wrapper from "../components/wrapper"
import Column from "../components/column"
import Title from "../components/title"
import Text from "../components/text"
import Button from "../components/button"
import Divider from "../components/divider"
import List from "../components/list"
import ListItem from "../components/listItem"
import Card from "../components/card"
import GalleryItem from "../components/galleryItem"
import SocialIcons from "../components/socialIcons"
import { StaticImage } from "gatsby-plugin-image"
import Dot from '../images/dot.svg'
import FAQ from "../components/faq"
import ButtonWrapper from "../components/buttonWrapper"


// markup
const IndexPage = () => {

  const faqs = [
    {
        question: 'How can we start a project together?',
        answer: 'Send me an e-mail at <a href="mailto:jweszter@gmail.com">jweszter@gmail.com</a>, or call me at <a href="tel:+421902691212">+421 902 691 212</a>. We will start with a brief discovery call in order for me to understand your project. Afterwards, we will define the project scope, set a time schedule, and I\'ll prepare a price estimate. Once everything is agreed upon, we can start work within 2 to 4 weeks.',
        /*answer: 'I\'m currently busy with ongoing engagements — but feel free to call or leave a message and we\'ll see if we\'re a good fit.',*/
        open: false
    },
    {
        question: 'How much will it cost?',
        answer: 'Website and application design usually starts at 20 000 CZK (850 EUR). Consultations are priced hourly at a rate of 2000 CZK (85 EUR) per hour. For a more precise price estimate or development pricing, please contact me via e-mail at <a href="mailto:jweszter@gmail.com">jweszter@gmail.com</a> – most of the time we\'ll be able to agree upon a project scope that will accommodate your budget.',
        open: false
    },
    {
        question: 'What information do you need to prepare an estimate?',
        answer: 'Generally, we will need to define a set of deliverables – a number of website pages, application screens, or project scope. It\'s usually best to conduct a brief phone/video call so I can talk you through what I need in detail.',
        open: false
    },
    {
        question: 'What else will I need to launch my website, application, or project?',
        answer: 'Since 2023, my sole focus is on design – you\'ll need a software developer down the road. In case you already have a trusted development team but are missing a UI/UX designer, I will be happy to collaborate.',
        open: false
    }
  ]

  return (
    <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Juraj Weszter – UI/UX Web and Application Design, Branding</title>
        <link rel="canonical" href="https://jurajweszter.com" />
        <link rel="preconnect" href="https://rsms.me/" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      </Helmet>
     
      <Nav />

      <Section pt='2rem' pb='5rem'>
        <Wrapper mobileOrder='reverse' content='columns' columnCount='2' align='center'>
          <Column width='40'>
            <StaticImage src='../images/title-photo.png' backgroundColor='rgb(28, 28, 32)'></StaticImage>
          </Column>
          <Column width='50' spacing='500' shift='075em'>
            <Title h1 size='900' maxWidth='9' revealOnScroll>
              I design web&shy;sites, applica&shy;tions, and user interfaces
            </Title>
            <Text size='900' maxWidth='28' revealOnScroll delay='150ms'>
              I'm a freelance digital designer based in Brno, Czech
              Republic. I design thoughtful, cohesive, and unified
              websites, app&shy;lications, interfaces, and brands.
            </Text>
            <ButtonWrapper revealOnScroll delay='300ms'>
              <Button href='#contact' buttonStyle='basic' size='500'>
                Get in touch
              </Button>
              <Button href='#work' buttonStyle='basic' size='500' background='charcoal'>
                Recent work
              </Button>
            </ButtonWrapper>
          </Column>
        </Wrapper>
      </Section>

      <Section>
        <Divider />
      </Section>

      <Section pt='4.5rem' pb='4.5rem' id='services'>
        <Wrapper content='columns' columnCount='4'>
          <Column width='100' spacing='600' revealOnScroll>
            <Title h2 size='100'>
              Website design
            </Title>
            <Text size='500'>
              Modern, functional website design that aims to engage, convert, and sell.
            </Text>
            <List color='cyan'>
              <ListItem>
                Landing pages
              </ListItem>
              <ListItem>
                Company websites
              </ListItem>
              <ListItem>
                Personal websites
              </ListItem>
            </List>
          </Column>
          <Column width='100' spacing='600' revealOnScroll delay='150ms'>
            <Title h2 size='100'>
              Application design
            </Title>
            <Text size='500'>
              Flexible, component-based app design with focus on usability and functionality.
            </Text>
            <List color='blue'>
              <ListItem>
                Usability-driven design
              </ListItem>
              <ListItem>
                Best UX Practices
              </ListItem>
              <ListItem>
                Component-based
              </ListItem>
            </List>
          </Column>
          <Column width='100' spacing='600' revealOnScroll delay='300ms'>
            <Title h2 size='100'>
              Branding
            </Title>
            <Text size='500'>
              Comprehensive branding for digital products, services and SaaS applications.
            </Text>
            <List color='purple'>
              <ListItem>
                Logo design
              </ListItem>
              <ListItem>
                Visual identities
              </ListItem>
              <ListItem>
                Manuals &amp; guidelines
              </ListItem>
            </List>
          </Column>
          <Column width='100' spacing='600' revealOnScroll delay='450ms'>
            <Title h2 size='100'>
              Development
            </Title>
            <Text size='500'>
              Websites and apps built with React, Vue.js, WordPress, or Webflow. 
            </Text>
            <List color='salmon'>
              <ListItem>
                Full Development
              </ListItem>
              <ListItem>
                Component libraries
              </ListItem>
              <ListItem>
                React, Vue, WordPress
              </ListItem>
            </List>
          </Column>
        </Wrapper>
      </Section>
    
      <Section pt='4rem' pb='5rem'>
        <Wrapper content='cards'>
          <Card span='full' image2='../images/blank.png'>
            <Column spacing='500' revealOnScroll>
              <Title anim='slide-up' size='700'>
                Competitive website and app design based on best UX practices
              </Title>
              <Text size='900'>
                Elicit engagement, eliminate friction, and drive results
                by leveraging best UX practices. I'll ensure your project
                is functional, intuitive, and ready to compete.
              </Text>
              <ButtonWrapper>
                <Button href='#contact' buttonStyle='basic' size='500'>
                  Get a Price Estimate
                </Button>
                <Button href='#work' buttonStyle='basic' size='500' background='charcoal'>
                  Recent work
                </Button>
              </ButtonWrapper>
            </Column>
          </Card>

          <Card span='full' image1='../images/blank.png'>
            <Column spacing='500' revealOnScroll>
              <Title size='700'>
                Modern and efficient landing pages
              </Title>
              <Text size='900' maxWidth=''>
                Turn your visitors into customers. I'll design, develop
                and&nbsp;launch a conversion-oriented landing page for your
                product, service, or application.
              </Text>
              <ButtonWrapper>
                <Button href='#contact' buttonStyle='basic' size='500'>
                  Get a Price Estimate
                </Button>
                <Button href='#work' buttonStyle='basic' size='500' background='charcoal'>
                  Recent work
                </Button>
              </ButtonWrapper>
            </Column>
          </Card>

          <Card span='half'>
            <Column spacing='500' revealOnScroll>
              <Title size='700'>
                Creative digital project branding
              </Title>
              <Text size='900' maxWidth=''>
                Make an impression, show confidence, and build trust
                with a strong brand identity. Let's give your project
                a unified and memorable face that will represent it
                as it grows.
              </Text>
              <ButtonWrapper>
                <Button href='#contact' buttonStyle='basic' size='500'>
                  Get a Price Estimate
                </Button>
              </ButtonWrapper>
            </Column>
          </Card>

          <Card span='half'>
            <Column spacing='500' revealOnScroll>
              <Title size='700'>
                Custom services and consulting
              </Title>
              <Text size='900'>
                Does your project need a UI/UX designer? I'm available to lead, assit, or consult. Let's
                talk — we'll discuss your project and I'll
                let you know if I can help.
              </Text>              
              <ButtonWrapper>
                <Button href='mailto:jweszter@gmail.com?subject=Call Request&body=Please call me at +421 ...' buttonStyle='basic' size='500'>
                  Request Call
                </Button>
                <Button href='tel:+421902691212' buttonStyle='basic' background='charcoal' size='500'>
                  <img src={Dot} alt='' class='button__icon button__icon--dot'/>
                  +421 902 691 212
                </Button>
              </ButtonWrapper>
            </Column>
          </Card>
        </Wrapper>
      </Section>

      <Section pt='5rem' pb='8rem' id='about'>
        <Wrapper content='columns' columnCount='2' padded='true'>
          <Column spacing='400' sticky='true' mobileAlign='center'>
            <StaticImage src='../images/portrait.png' className='image image--portrait' backgroundColor='rgb(28, 28, 32)' imgStyle={{ borderRadius: '100%'}}/>
            <Title size='50'>
              Juraj Weszter
            </Title>
            <Text size='500'>
              jweszter@gmail.com<br/>+421 902 691 212
            </Text>
            <Button href='#contact' buttonStyle='arrow' size='400' icon='arrow'>
              Get in touch
            </Button>
          </Column>
          <Column spacing='600'>
            <Title size='500'>
              About
            </Title>
            <Text size='900' justify='true'>
              With an education in <a class='iconLink iconLink--vut' href='https://www.linkedin.com/in/jurajweszter/'>applied mathematics</a> and over 5 years of experiece as a UX/UI designer, I have amassed a breadth of knowledge connecting visual design, user experience, and technology. Before starting freelancing in August 2021, I held the role of lead designer at an agile web development agency. Reponsible for both user experience and visual design, I helped create numerous web projects and lead the design of a growing SaaS application.
            </Text>
            <Text size='900' justify='true'>
              Aiming to bridge the gap between visuals and functionality, I leverage best UX practices to create standout digital experiences that drive user engagement, eliminate friction, and achieve tangible results. I synthesize the principles of Atomic Design with the practice of a component-based approach, paving way to well-structured, robust digital products that move fast in development, evolve easily, and scale seamlessly.
            </Text>
            <FAQ faqs={faqs} />
          </Column>
        </Wrapper>
      </Section>

      <Section pt='3rem' pb='1rem' id='work'>
        <Divider title='Recent&nbsp;work' />
      </Section>

      <Section pt='3rem' pb='5rem'>
        <Wrapper content='gallery'>
          <GalleryItem span='full' linkTo='/projects/bona'>
            <StaticImage layout='fullWidth' src={'../images/previews/bona/1.png'} />
          </GalleryItem>
          <GalleryItem span='half' linkTo='/projects/bona'>
            <StaticImage layout='fullWidth' src={'../images/previews/bona/2.png'} />
          </GalleryItem>
          <GalleryItem span='half' linkTo='/projects/bona'>
            <StaticImage layout='fullWidth' src={'../images/previews/bona/3.png'} />
          </GalleryItem>
          <GalleryItem span='full' linkTo='/projects/maily'>
            <StaticImage layout='fullWidth' src={'../images/previews/maily/mockup--2.png'} />
          </GalleryItem>
          <GalleryItem span='half' linkTo='/projects/maily'>
            <StaticImage layout='fullWidth' src={'../images/previews/maily/mockup--0.png'} />
          </GalleryItem>
          <GalleryItem span='half' linkTo='/projects/maily'>
            <StaticImage layout='fullWidth' src={'../images/previews/maily/mockup--1.png'} />
          </GalleryItem>
          <GalleryItem span='full' linkTo='/projects/hit'>
            <StaticImage layout='fullWidth' src={'../images/previews/hit/5.png'} />
          </GalleryItem>
          <GalleryItem span='half' linkTo='/projects/hit'>
            <StaticImage layout='fullWidth' src={'../images/previews/hit/2.png'} />
          </GalleryItem>
          <GalleryItem span='half' linkTo='/projects/hit'>
            <StaticImage layout='fullWidth' src={'../images/previews/hit/7.png'} />
          </GalleryItem>
        </Wrapper>
      </Section>

      <Section pt='3.5rem' pb='5rem' id='contact'>
        <Wrapper content='columns' columnCount='1'>
          <Column align='center' spacing='500'>
            <StaticImage src='../images/portrait--2.png' backgroundColor='rgb(28, 28, 32)' className='image image--portrait' imgStyle={{ borderRadius: '100%'}} />
            <Title size='700' maxWidth='13' revealOnScroll>
              Let's design your website, application, or digital project
              {/*Not open to new projects.*/}
            </Title>
            <Text size='900' maxWidth='24' revealOnScroll delay='150ms'>
              {/*I'm currently busy with ongoing engagements — but feel free to call or leave a message and we'll see if we're a good fit.*/}
              Call or leave a message — we'll dicuss your project and see if we're a good fit.
            </Text>
            <ButtonWrapper revealOnScroll delay='300ms'>
              <Button href='mailto:jweszter@gmail.com' buttonStyle='basic' size='500'>
                jweszter@gmail.com
              </Button>
              <Button href='tel:+421902691212' buttonStyle='basic' size='500' background='charcoal'>
                <img src={Dot} alt='' class='button__icon button__icon--dot'/>
                +421 902 691 212
              </Button>
            </ButtonWrapper>
          </Column>
        </Wrapper>
      </Section>

      <Section pt='3rem' pb='3rem'>
        <Divider />
      </Section>

      <Section pt='2rem' pb='6rem'>
        <Wrapper content='columns' columnCount='3' stretch='true'>
          <Column spacing='600' width='100' mobileAlign='center'>
            <Title size='50'>
              Ing. Juraj Weszter
            </Title>
            <Text size='300'>
              IČO 11747978<br />
              Nové sady 988/2, 602 00 Brno<br />
              Nejsem plátcem DPH.
            </Text>
          </Column>
          <Column align='center' spacing='600' width='100'>
            <Text size='300'>
              Fyzická osoba zapsaná v Živnostenském rejstříku Magistrátu města Brna.
            </Text>
            <SocialIcons />
          </Column>
          <Column align='right' spacing='600' width='100' mobileAlign='center'>
            <Text size='300'>
              E-mail
            </Text>
            <Title size='50'>
              jweszter@gmail.com
            </Title>
            <Text size='300'>
              Phone / WhatsApp
            </Text>
            <Title size='50'>
              +421 902 691 212
            </Title>
          </Column>
        </Wrapper>
      </Section>

    </Layout>
  )
}

export default IndexPage
