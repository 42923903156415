import React from 'react'
import classNames from 'classnames';

function List(props) {
    
    var ClassNames = classNames({
        'list' : true,
        'list--cyan': props.color === 'cyan',
        'list--blue': props.color === 'blue',
        'list--purple': props.color === 'purple',
        'list--salmon': props.color === 'salmon'
    });

    return (
        <ul className={ClassNames}>
            {props.children}
        </ul>
    );
}

export default List;
