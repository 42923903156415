import React from 'react'
import classNames from 'classnames'
import { useInView } from "react-intersection-observer"

function HotText(props) {

    const [ref, inView] = useInView({ threshold: 0.15, triggerOnce: true })

    var ClassNames = classNames({
        'text' : true,
        'text--justified' : props.justify === 'true',
        'text--100' : props.size === '100',
        'text--300' : props.size === '300',
        'text--500' : props.size === '500',
        'text--700' : props.size === '700',
        'text--900' : props.size === '900',
        'revealOnScroll' : props.revealOnScroll
    });

    return (
        <p dangerouslySetInnerHTML={{ __html: props.html}} className={ClassNames + (props.revealOnScroll && inView ? ' isVisible' : '')} ref={props.revealOnScroll ? ref : null} style={{maxWidth : props.maxWidth + 'em', '--delay': props.delay}}>
        </p>
    );
}

export default HotText;