import React from 'react'
import classNames from 'classnames';

function ListItem(props) {
    
    var ClassNames = classNames({
        'list__item' : true
    });

    return (
        <li className={ClassNames}>
            { props.children }
        </li>
    );
}

export default ListItem;
