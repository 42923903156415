import React, { useState } from 'react'
import '../components/faq.scss'
import Title from './title'
import HotText from './hotText'

const FAQ = ( props ) => {

    const [faqs, setfaqs] = useState(props.faqs)
    const toggleFAQ = index => {

        setfaqs(faqs.map((faq, i) => {
        if ( i === index ) {
            faq.open = !faq.open
        } else {
            faq.open = false;
        }
        
        return faq;
        }))
    }
    
    return (
        <div className='faq'>
            {faqs.map((faq, i) => (
            <div 
                className={'faq__item' + (faq.open ? ' faq__item--open' : '')}
                key={i}
                role="button"
                tabIndex={0}
                onClick={() => toggleFAQ(i)}
                onKeyDown={() => toggleFAQ(i)}
            >
                <div className='faq__question'>
                    <Title size='50'>
                        { faq.question }
                    </Title>
                </div>
                <div className='faq__answer'>
                    <HotText html={faq.answer} size='700'>
                    </HotText>
                </div>
            </div>
            ))}
        </div>
    );
}

export default FAQ