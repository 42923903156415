import React from 'react'
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';

function Card(props) {
    
    var ClassNames = classNames({
        'card' : true,
        'card--full' : props.span === 'full',
        'card--half' : props.span === 'half'
    });

    var ClassNames_inner = classNames({
        'card__inner' : true
    });
    
    var ClassNames_image = classNames({
        'card__image' : true
    });

    return (
        <div className={ClassNames}>
            <div className={ClassNames_inner}>
                { props.children }
            </div>
            { props.image1 && 
                <div className={ClassNames_image}>
                    <StaticImage src='../images/ui-photo--3.png' />
                </div>
            }
            { props.image2 && 
                <div className={ClassNames_image}>
                    <StaticImage src='../images/ui-photo--2.png' />
                </div>
            }
        </div>
    );
}

export default Card;
